<template>
  <div class="k2-text-field">
    <komodo-input-label
      v-if="label || explainerTooltip || subtext"
      class="k2-text-field__input-label"
      :state="state"
      :explainer-icon="explainerIcon"
      :explainer-tooltip="explainerTooltip"
      :explainer-tooltip-max-width="explainerTooltipMaxWidth"
    >
      <template
        v-if="label"
      >
        {{ label }}
      </template>
      <template
        v-if="subtext"
        #subtext
      >
        {{ subtext }}
      </template>
    </komodo-input-label>
    <komodo-input
      v-model="inputValue"
      class="k2-text-field__input"
      :disabled="disabled"
      :autofocus="autofocus"
      :clearable="clearable"
      :placeholder="placeholder"
      :prepend-icon="prependIcon"
      :required="required"
      :size="size"
      :state="state"
      :type="type"
      :emit-on-enter="emitOnEnter"
      @handle:enter-key-press="$emit('handle:enter-key-press', $event)"
    >
      <slot v-if="!prependIcon" />
    </komodo-input>
    <komodo-input-hint
      v-if="hint"
      class="k2-text-field__hint"
      :state="state"
    >
      {{ hint }}
    </komodo-input-hint>
  </div>
</template>

<script lang="ts">
import KomodoInput from 'komodo2/components/KomodoInput.vue'
import KomodoInputLabel from 'komodo2/components/KomodoInputLabel.vue'
import KomodoInputHint from 'komodo2/components/KomodoInputHint.vue'

import { InputSize, InputState, InputType } from 'komodo2/types.ts'

export default {
  name: 'KomodoTextField'
}
</script>

<script setup lang="ts">
interface Props {
  /**
   * When true, the text field takes focus when it is created
   */
  autofocus?: boolean
  /**
   * Whether to show clear input button
   */
  clearable?: boolean
  /**
   * The icon that shows the explainer tooltip on hover
   */
  explainerIcon?: string
  /**
   * The tooltip shown on explainer hover. Explainer is hidden if this is left empty.
   */
  explainerTooltip?: string
  /**
   * Max width of the explainer tooltip in pixels
   */
  explainerTooltipMaxWidth?: number
  /**
   * The input hint text
   */
  hint?: string
  /**
   * The input label text
   */
  label?: string
  /**
   * The text shown when the input is empty
   */
  placeholder?: string
  /**
   * The icon that should be shown to the left of the input area
   */
  prependIcon?: string
  /**
   * Whether the input is required
   */
  required?: boolean
  /**
   * The size to render the input
   */
  size?: InputSize
  /**
   * State to style the input
   */
  state?: InputState
  /**
   * The input label subtext
   */
  subtext?: string
  /**
   * The input type
   */
  type?: InputType
  /**
   * The input disabled
   */
  disabled?: boolean
  /**
   * Allows to Pass Up String on Enter Press
   */
  emitOnEnter?: boolean
}

withDefaults(defineProps<Props>(), {
  autofocus: false,
  clearable: false,
  explainerIcon: 'info',
  explainerTooltip: undefined,
  explainerTooltipMaxWidth: undefined,
  hint: undefined,
  label: undefined,
  placeholder: undefined,
  prependIcon: undefined,
  required: false,
  size: InputSize.Default,
  state: InputState.Default,
  subtext: undefined,
  type: InputType.Text,
  disabled: false,
  emitOnEnter: false
})

defineEmits<{
  (e: 'update:model-value', value: string | number | null): void
  (e: 'handle:enter-key-press', value: string): void
}>()

/**
 * The input field value
 */
const inputValue = defineModel<string | null>({ default: null })
</script>

<style lang="scss" scoped>
.k2-text-field {
  display: flex;
  flex-flow: column nowrap;

  &__hint {
    margin-top: 4px;
  }
}
</style>
